<template>

  <div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      ref="sevk-modal"
      title="Konsinye Sevk Oluştur"
      size="xl"
      hide-footer
    >
      <sevk-list :data="selectedConsignment" />
    </b-modal>
    <!-- Alındı olarak işaretle modal-->
    <b-modal
      id="mark_as_returned"
      ref="mark_as_returned"
      v-model="showMarkAsReturnedModal"
      :title="$t('Alındı Olarak İşaretle')"
      :ok-title="$t('Kaydet')"
      cancel-variant="outline-secondary"
      @ok="markAsReturned"
    >
      <!-- Teslim Alınacağı Tarih -->
      <b-form-group

        :label="$t('Teslim Alındığı Tarih')"
        label-for="return_completion_date"
      >
        <b-form-datepicker
          id="return_completion_date"
          v-model="returnCompletionDate"
          class="mb-1"
        />
      </b-form-group>
    </b-modal>
    <!-- Filters -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('read','m_consignment_edit')"
                variant="primary"
                :to="{ name: 'consignments-add' }"
              >
                <span class="text-nowrap">{{ $t('Konsinye Ekle') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0 "
          >

            <b-button
              variant="dark"
              size="sm"
              @click="toExcel"
            >
              <feather-icon
                icon="FileTextIcon"
                class="mr-50"
              />
              <span class="text-nowrap">{{ $t('Tümünü Excel Olarak İndir') }}</span>
            </b-button>

          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refConsignmentListTable"
        class="position-relative"
        :items="fetchConsignments"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        small
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(consignment_number)="data">
          <b-media vertical-align="center">

            <b-link
              :to="{ name: 'consignments-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.consignment_number }}
            </b-link>

          </b-media>
        </template>
        <!-- Column: Products -->
        <template #cell(products)="data">
          <b-media vertical-align="center">

            <app-collapse>

              <app-collapse-item :title="$t('Ürünleri Gör')+' ('+data.item.products.length+' '+$t('Ürün')+')'">
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in data.item.products"
                    :key="index"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>{{ item.name }}</span>
                    <b-badge
                      variant="primary"
                      pill
                      class="badge-round"
                    >
                      {{ item.piece+' '+item.unit_name }} /{{ item.stock }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>
              </app-collapse-item>

            </app-collapse>

          </b-media>
        </template>
        <!-- Column: User -->
        <template #cell(return_completion_date)="data">

          <b-badge
            v-if="data.item.return_completion_date==null"
            variant="warning"
            pill
            class="badge-round"
          >{{ $t('Teslim Alınmadı') }}</b-badge>
          <b-badge
            v-if="data.item.return_completion_date!=null"
            variant="primary"
            pill
            class="badge-round"
          >{{ data.item.return_completion_date }} {{ $t('Tarihinde Teslim Alındı') }}</b-badge>
        </template>
        <template #cell(status)="data">

          <b-badge
            v-if="data.item.status==1"
            variant="info"
            pill
            class="badge-round"
          >{{ $t('Açık') }}</b-badge>
          <b-badge
            v-if="data.item.status==2 && data.item.sevk_stats.sevked_products<=0"
            variant="warning"
            pill
            class="badge-round"
          >{{ $t('Onaylanmış. Lütfen Sevk Ediniz') }}</b-badge>
          <b-badge
            v-if="data.item.status==2 && data.item.sevk_stats.sevked_products>0 &&data.item.sevk_stats.sevked_products< data.item.sevk_stats.total_products"
            variant="secondary"
            pill
            class="badge-round"
          >{{ data.item.sevk_stats.sevked_products+'/'+data.item.sevk_stats.total_products }} {{ $t('Sevk Edilen') }}</b-badge>
          <b-badge
            v-if="data.item.status==2 && data.item.sevk_stats.sevked_products>0 &&data.item.sevk_stats.sevked_products== data.item.sevk_stats.total_products"
            variant="success"
            pill
            class="badge-round"
          > {{ $t('Tamamı Sevk Edildi') }}</b-badge>
          <b-badge
            v-if="data.item.status==0"
            variant="primary"
            pill
            class="badge-round"
          >{{ $t('Kapalı') }}</b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="data.item.return_completion_date==null"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="data.item.status==1"
              @click="approveConsignment(data.item.id)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">{{ $t('Onayla') }} </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status==2"
              @click="openSevkModal(data.item)"
            >
              <feather-icon icon="TruckIcon" />
              <span class="align-middle ml-50">{{ $t('Sevk Et') }} </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('read','m_consignment_edit') ||data.item.status==1"
              :to="{ name: 'consignments-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Düzenle') }} </span>
            </b-dropdown-item>
            <b-dropdown-item @click="showModal(data.item.id)">
              <feather-icon icon="CornerRightDownIcon" />
              <span class="align-middle ml-50">{{ $t('Alındı Olarak İşaretle') }} </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Gösterilen') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalConsignments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BModal, VBModal, BTable, BMedia, BLink, BFormGroup, BFormDatepicker,
  BBadge, BDropdown, BDropdownItem, BPagination, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import useConsignmentsList from './useConsignmentList'
import consignmentStoreModule from '../consignmentsStoreModule'
import sevkList from '../uyum-sevk/SevkList.vue'

export default {
  components: {
    BCard,
    BRow,
    sevkList,
    BCol,
    BFormInput,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BFormGroup,
    BFormDatepicker,
    BDropdownItem,
    BPagination,
    BListGroup,
    BModal,

    BListGroupItem,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      selectedId: 0,
      selectedConsignment: null,
    }
  },
  methods: {
    openSevkModal(consignment) {
      this.selectedConsignment = consignment
      this.$refs['sevk-modal'].show()
    },
    approveConsignment(consignmentId) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Konsinyeyi onayladıktan sonra konsinye üzerinde düzenleme, silme gibi işlemler yapılamayacaktır. konsinye onaylama işlemini yaptıktan sonra sevk işlemi yapabilirsiniz.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Onaylıyorum'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-consignment/approveConsignment', { consignment_id: consignmentId })
            .then(() => {
              this.$refs.refConsignmentListTable.refresh()
              this.$bvToast.toast(this.$t('Konsinye onaylandı durumuna geçirildi ve sevk işlemine hazır.'), {
                title: this.$t('İşlem Başarılı'),
                variant: 'success',
                solid: false,
              })
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    toExcel() {
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/consignments-excel?token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    showModal(id) {
      this.selectedId = id
      this.showMarkAsReturnedModal = true
      this.returnCompletionDate = ''
    },
    markAsReturned() {
      const id = this.selectedId
      this.showMarkAsReturnedModal = false

      store.dispatch('app-consignment/markAsReturned', { id, return_completion_date: this.returnCompletionDate })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.showSuccessMessage()
          this.$refs.refConsignmentListTable.refresh()
        })
        .catch(() => {
          this.showErrorMessage()
        })
      this.selectedId = 0
    },
    addIsSuccess(id) {
      this.editConsignmentId = id
      this.isEditConsignmentSidebarActive = true
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-consignment'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, consignmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const editConsignmentId = ref(0)
    const isAddNewConsignmentSidebarActive = ref(false)
    const isEditConsignmentSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchConsignments,
      tableColumns,
      perPage,
      currentPage,
      totalConsignments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refConsignmentListTable,
      refetchData,

      // UI

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useConsignmentsList()

    return {

      // Sidebar
      isAddNewConsignmentSidebarActive,
      isEditConsignmentSidebarActive,
      fetchConsignments,
      tableColumns,
      perPage,
      currentPage,
      totalConsignments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      editConsignmentId,
      isSortDirDesc,
      refConsignmentListTable,
      refetchData,

      // Filter
      avatarText,

      // UI

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
