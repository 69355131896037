import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConsignments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/consignments', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchConsignment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/consignments/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addConsignment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/consignments', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveConsignment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/consignments-approve', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    separateSevkData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/consignments-sevk-separate', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSevkData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/consignments-sevk-update', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendSevk(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/consignments-sevk-send', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSevkData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/consignments-sevk-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateConsignment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/consignments/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    markAsReturned(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/consignment-mark-as-returned/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    toExcel(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/consignments-excel', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
